<template>
  <div class="app-container">
    <div class="top" style="margin: 0 0 10px 0">
      <el-button
        type="success"
        icon="el-icon-plus"
        size="small"
        @click="showAdd"
        >新增</el-button
      >
    </div>
    <el-table
      :data="listData"
      style="width: 100%; margin-bottom: 20px; text-align: center"
      row-key="id"
      border
      default-expand-all
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
    >
      <el-table-column
        prop="name"
        label="部门名称"
        sortable
        width="180"
      ></el-table-column>
      <el-table-column prop="leadPersonName" label="负责人"></el-table-column>
      <el-table-column prop="leadPersonPhone" label="电话"></el-table-column>
      <el-table-column prop="leadPersonMail" label="邮件"></el-table-column>
      <el-table-column prop="seq" label="排序" width="180"></el-table-column>
      <el-table-column label="操作" width="180" align="center">
        <template slot-scope="scope">
          <el-link
            type="primary"
            icon="el-icon-edit"
            @click="showEdit(scope.row)"
            class="m-r-20"
            :underline="false"
            >编辑</el-link
          >
          <el-link
            type="danger"
            icon="el-icon-delete"
            :underline="false"
            @click="handleDel(scope.row.id)"
            >删除</el-link
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :title="handelType === 'new' ? '新增部门' : '编辑部门'"
      :visible.sync="dialogVisible"
      width="800px"
      center
    >
      <el-form
        ref="ruleForm"
        :model="form"
        label-width="120px"
        :inline="true"
        :rules="rules"
      >
        <el-form-item label="上级部门" prop="pid">
          <el-cascader
            class="commoninline"
            :options="departmentTree"
            :props="{
              value: 'id',
              label: 'name',
              children: 'children',
              emitPath: false,
              checkStrictly: true,
            }"
            clearable
            placeholder="按部门查询"
            v-model="form.pid"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="部门名称" prop="name">
          <el-input v-model="form.name" />
        </el-form-item>

        <el-form-item label="负责人" prop="leadPersonName">
          <el-input v-model="form.leadPersonName" />
        </el-form-item>

        <el-form-item label="电话" prop="seq">
          <el-input v-model="form.leadPersonPhone" />
        </el-form-item>

        <el-form-item label="邮箱" prop="seq">
          <el-input v-model="form.leadPersonMail" />
        </el-form-item>
        <el-form-item label="排序" prop="seq">
          <el-input v-model="form.seq" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          v-if="handelType === 'new'"
          type="primary"
          @click="handleAdd('ruleForm')"
          >确 定</el-button
        >
        <el-button v-else type="primary" @click="handleEdit('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api/common";

export default {
  data() {
    // let self = this;
    return {
      rules: {
        name: [
          {
            required: true,
            message: "请输入名称",
            trigger: ["blur", "change"],
          },
        ],
        pid: [
          {
            required: true,
            message: "请选择上级部门",
            trigger: ["blur", "change"],
          },
        ],
      },
      listData: [],
      form: {},
      dialogVisible: false,
      handelType: "new",
      departmentTree: [],
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    async getDataList() {
      let res = await api.commonGet({ apiName: "/department/getTreeList" });
      this.listData = res.data;
      this.departmentTree = this.listData;
    },

    showAdd() {
      this.handelType = "new";
      this.form = {};
      this.form.seq = 0;
      this.dialogVisible = true;
    },
    handleAdd(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let obj = Object.assign({}, this.form, {
            apiName: "/department/create",
          });
          api.commonPost(obj).then((res) => {
            if (res.code == 200) {
              this.$message({ message: "新增成功", type: "success" });
              this.getDataList();
              this.dialogVisible = false;
            } else {
              this.$message({ message: res.message, type: "error" });
            }
          });
        } else {
          return false;
        }
      });
    },
    handleDel(id) {
      this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        let arr = [];
        arr.push(id);

        api
          .commonPost({ ids: arr, apiName: "/department/remove" })
          .then((res) => {
            if (res.code == 200) {
              this.$message({ message: "删除成功", type: "success" });
            } else {
              this.$message({ message: res.message, type: "error" });
            }
            this.getDataList();
          });
      });
    },
    showEdit(obj) {
      this.handelType = "edit";
      this.form = obj;
      this.dialogVisible = true;
    },
    handleEdit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let obj = Object.assign({}, this.form, {
            apiName: "/department/update",
          });
          api.commonPost(obj).then((res) => {
            if (res.code == 200) {
              this.$message({ message: "更新成功", type: "success" });
              this.getDataList();
              this.dialogVisible = false;
            } else {
              this.$message({ message: res.message, type: "error" });
            }
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.commoninline {
  width: 200px;
}
.search {
  display: flex;
}
.el-select {
  /deep/ .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
